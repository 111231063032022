import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import { CallToAction, Hero, Section, ProductCard, SEO } from "components"

const CategoriesTemplate = ({ data }) => {
  let products = data.products.edges
  let title = data.sanityCategory.title
  return (
    <>
      <SEO title={title} />
      <Hero
        tag="Categoria de Produto"
        title={title}
        description={`Confira todos os produtos da categoria ${title} e encontre o produto ideal para o seu negócio.`}
        overlay
      />
      <Section>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {products.map(({ node: { title, slug, department, image } }) => {
            return (
              <ProductCard
                key={slug.current}
                title={title}
                to={`/produtos/${slug.current}`}
                department={department}
                image={image}
              />
            )
          })}
        </div>
      </Section>
      <CallToAction />
    </>
  )
}

CategoriesTemplate.propTypes = {
  data: PropTypes.oneOfType([PropTypes.node, PropTypes.object]).isRequired,
}

export default CategoriesTemplate

export const query = graphql`
  query GET_CATEGORY($slug: String, $title: [String]) {
    sanityCategory(slug: { current: { eq: $slug } }) {
      title
      description
    }
    products: allSanityProduct(
      filter: { categories: { elemMatch: { title: { in: $title } } } }
      sort: { fields: title, order: ASC }
    ) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          image {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
          department {
            title
            slug {
              current
            }
          }
        }
      }
    }
  }
`
